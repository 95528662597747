<!--
 * @Author: yuanshengsong
 * @Date: 2022-03-17 10:09:51
 * @LastEditTime: 2022-03-22 10:58:17
 * @LastEditors: yuanshengsong
 * @Description:
 * @param: paramAlias
 * @return: returnAlias
-->
<template>
  <div class="edit-table">
    <vxe-toolbar v-if="!disabled">
      <template v-slot:buttons>
        <el-link type="primary" icon="el-icon-plus" @click="addTable">新增</el-link>
        <!-- <el-link class="text-red" icon="el-icon-delete" @click="deleteTable">删除数据</el-link> -->
        <!-- <el-link class="text-red" icon="el-icon-delete" @click="deleteAllTable">清空所有</el-link> -->
      </template>
    </vxe-toolbar>
    <vxe-table show-overflow="" ref="fineTable" :data="subjectList"
      @checkbox-all="selectAllChangeEvent"  @checkbox-change="selectChangeEvent">
      <!-- <vxe-column type="checkbox" width="60" fixed="left"></vxe-column> -->
      <vxe-table-column min-width="100" field="productCode" title="产品编码"></vxe-table-column>
      <vxe-table-column min-width="100" field="productName" title="产品名称"></vxe-table-column>
      <vxe-table-column min-width="100" field="ext3" title="基本单位"></vxe-table-column>
      <vxe-table-column min-width="100" field="spec" title="包装规格"></vxe-table-column>
      <vxe-table-column min-width="100" field="productType" title="商品类型"></vxe-table-column>
      <vxe-table-column
        fixed="right"
        title="操作"
        align="center"
        width="80"
        v-if="!disabled"
      >
        <template slot-scope="scope">
          <el-popconfirm
            title="此操作将永久删除该数据？"
            confirm-button-type="text"
            @confirm="removeRow(scope.row)"
          >
            <el-button
              v-if="!scope.row.disabled"

              class="text-red"
              slot="reference"
              type="text"
              icon="el-icon-delete"
            ></el-button>
          </el-popconfirm>
        </template>
      </vxe-table-column>
    </vxe-table>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>

import request from '@/found/utils/request';
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'BudgetSubject',
  components: {
    SelectConfig,
  },
  props: {
    value: Array,
    disabled: Boolean,
    getValue: Function,
  },
  watch: {
    value() {
      this.subjectList = this.value.map((a) => ({
        ...a,
      }));
    },
  },
  data() {
    return {
      subjectList: [], // 当前关联费用科目数据
      selection: [], // 选中行
    };
  },
  methods: {
    selectAllChangeEvent({ selection }) {
      this.selection = selection;
    },
    selectChangeEvent({ selection }) {
      this.selection = selection;
    },
    // 删除行
    removeRow(row) {
      const index = this.subjectList.findIndex((a) => a.productCode === row.productCode);
      this.subjectList.splice(index, 1);
      this.$emit('change', this.subjectList);
    },
    // 删除数据
    deleteTable() {
      if (this.selection.length === 0) {
        return this.$message.error('请先选择要删除的数据');
      }
      this.$confirm('确认要删除选中的表格数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.selection.forEach((a) => {
          const index = this.subjectList.findIndex((b) => b.productCode === a.productCode);
          this.subjectList.splice(index, 1);
        });
        this.$emit('change', this.subjectList);
      });
    },
    // 清空数据
    deleteAllTable() {
      this.$confirm('确认要清空表格数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$refs.fineTable.remove();
        this.subjectList = [];
        this.$emit('clear');
      });
    },
    // 校验预算的产品系列是是否与活动的产品匹配
    productLevelCheck(data) {
      request.post('/cps-mobile/consumerscanactivitymain/productLevelCheck', {
        productReqVos: data,
        budgetCode: this.getValue('budgetCode'),
        budgetName: this.getValue('budgetName'),
      }).then((res) => {
        console.log(res, 777);
      }).catch((error) => {
        console.log(error, 9999);
      });
    },
    // 新增数据
    addTable() {
      const productLevelCode = this.getValue('productLevelCode');
      const params = {
        functionCode: 'participating_products_list_1',
        data: this.subjectList,
        idKey: 'productCode',
        paramData: {
          productLevelCode,
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 弹框选中后带出
    onGetSelect(val) {
      // let newSubjectList = [];
      // this.subjectList.forEach((v) => {
      //   newSubjectList = val.filter((m) => m.productCode !== v.productCode);
      // });
      // if (!this.productLevelCheck(newSubjectList)) return false;
      this.subjectList = val.map((a) => ({
        ...a,
        // budgetCode: a.feeBudgetCode,
      }));
      this.$emit('change', this.subjectList);
    },
  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;
}
  .text-red {
    color: #f56c6c !important;
  }
</style>
