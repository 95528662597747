var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeScanCodeType
    ? _c(
        "div",
        [
          !_vm.disabled
            ? _c("vxe-toolbar", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "buttons",
                      fn: function () {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.openModal },
                            },
                            [_vm._v("添加" + _vm._s(_vm.buttonText))]
                          ),
                          _c("el-button", { on: { click: _vm.clear } }, [
                            _vm._v("清空"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  579911181
                ),
              })
            : _vm._e(),
          _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                data: _vm.tableData.slice(
                  (_vm.tablePage.currentPage - 1) * _vm.tablePage.pageSize,
                  _vm.tablePage.currentPage * _vm.tablePage.pageSize
                ),
              },
            },
            [
              _vm._l(_vm.columns, function (item, index) {
                return _c(
                  "vxe-table-column",
                  _vm._b(
                    {
                      key:
                        index +
                        (item.field || item.fixed) +
                        (item.formorder || ""),
                      attrs: {
                        cellRender: item.cellRender,
                        "tree-node": item.treeNode,
                        "min-width": item.minWidth || 100,
                      },
                    },
                    "vxe-table-column",
                    item,
                    false
                  )
                )
              }),
              !_vm.disabled
                ? _c("vxe-table-column", {
                    attrs: {
                      fixed: "right",
                      title: "操作",
                      align: "center",
                      width: "80",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: {
                                    title: "此操作将永久删除该数据？",
                                    "confirm-button-type": "text",
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.removeRow(
                                        scope.row,
                                        scope.rowIndex
                                      )
                                    },
                                  },
                                },
                                [
                                  !scope.row.disabled
                                    ? _c("el-button", {
                                        staticClass: "text-red",
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                          icon: "el-icon-delete",
                                        },
                                        slot: "reference",
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3781168242
                    ),
                  })
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "page-pageination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.tablePage.currentPage,
                  "page-size": _vm.tablePage.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.length,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _c("SelectConfig", {
            ref: "selectConfig",
            on: { onGetSelect: _vm.onGetSelect },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }