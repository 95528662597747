<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

import BudgetAccount from './components/budget_account.vue';
import Budget from './components/budget.vue';
import ParticipatingProducts from './components/participating_products.vue';
import RewardRules from './components/reward_rules.vue';
import ActivityType from './components/activityType';
import RewardRule1 from './components/reward_rules1.vue';
import CrackRule from './components/crack_rule.vue';
import RangeList from './components/rangeList.vue';

formCreate.component('BudgetAccount', BudgetAccount);
formCreate.component('Budget', Budget);
formCreate.component('ParticipatingProducts', ParticipatingProducts);
formCreate.component('RewardRules', RewardRules);
formCreate.component('ActivityType', ActivityType);
formCreate.component('RewardRule1', RewardRule1);
formCreate.component('CrackRule', CrackRule);
formCreate.component('RangeList', RangeList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      buttonText: {
        submit: '提交',
        close: '关闭',
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          },
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          },
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          },
        }],
      },
      buttons: {
        submit:
          this.formConfig.code !== 'view',
        close: true,
        loading: false,
      },
      scanTypeList: [],
      awardWayList: [],
      wayTypeList: [],
      beneficiaryList: [],
      ActiveScanCodeType: [],
    };
  },
  async created() {
    await this.getTypeList();
    console.log(this.formConfig);
    const rule = await this.getFormRule(
      'consumer_consumerScan_form',
      'CRM20220914000000003',
    );
    this.getDict();
  },
  methods: {
    // 获取数据字典
    getDict() {
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'ActiveScanCodeType',
      }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.ActiveScanCodeType = res.result.map((v) => ({
          ...v,
          label: v.dictValue,
          value: v.dictCode,
        }));
      });
    },
    setRule(item) {
      const v = item;
      console.log(item);
      console.log(this.formConfig.code);
      if (v.field === 'datePicker') {
        v.props = {
          ...v.props,
          type: 'datetimerange',
          pickerOptions: this.pickerOptions,
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: ['00:00:00', '23:59:59'],
        };
      } else if (v.field === 'orderDatePicker') {
        v.props = {
          ...v.props,
          type: 'datetimerange',
          pickerOptions: this.pickerOptions,
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: ['00:00:00', '23:59:59'],
        };
      } else if (v.field === 'activityType') {
        v.props = {
          ...v.props,
        };
        v.value = '1';
        v.on = {
          ...v.on,
          change: this.onActivity,
        };
      } else if (v.field === 'budgetSubjectName') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'budget_subjects_list_1',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
        v.on = {
          ...v.on,
          change: (e) => {
            this.setValue({
              budgetSubjectCode: e ? e[0].budgetSubjectsCode : '',
              budgetSubjectName: e ? e[0].budgetSubjectsName : '',
              budgetCode: '',
              budgetName: '',
              feeOrgCode: '',
              orgName: '',
              orgReqVos: [],
            });
          },
        };
      } else if (v.field === 'budgetName') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'budget_list_1',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
        v.on = {
          ...v.on,
          change: (e) => {
            this.setValue({
              budgetCode: e ? e[0].feeBudgetCode : '',
              budgetName: e ? e[0].feeBudgetTypeName : '',
              feeOrgCode: e ? e[0].orgCode : '',
              productLevelCode: e ? e[0].productLevelCode : '',
              orgName: '',
              orgReqVos: [],
            });
          },
        };
      } else if (v.field === 'orgName') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'business_organization_list_1',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
          autosize: { minRows: 1, maxRows: 6 },
        };
        v.on = {
          ...v.on,
          change: (e) => {
            let orgNames = '';
            const orgReqVos = [];
            e.forEach((m) => {
              const obj = m;
              orgNames += `${obj.orgName},`;
              orgReqVos.push({
                orgName: obj.orgName,
                orgCode: obj.orgCode,
              });
            });
            this.setValue({
              orgName: orgNames || '',
              orgReqVos: orgReqVos || [],
            });
          },
        };
      }
      if (v.field === 'activityDescribe') {
        v.props = {
          ...v.props,
          autosize: { minRows: 5 },
        };
      }
      if (v.field === 'productReqVos') {
        v.props = {
          ...v.props,
          getValue: (field) => this.getFieldValue(field),
          setValue: (value) => this.setValue(value),
          hiddenFields: (hidden, fields) => this.hiddenFields(hidden, fields),
        };
        v.on = {
          ...v.on,
          change: (val) => {
            this.setValue({ productReqVos: val });
          },
        };
      }
      if (v.field === 'wayRule') {
        v.props = {
          ...v.props,
          parentCode: 'CRM20220914000000003',
          functionCode: 'reward_rules_list',
          // reward: this.reward,
          getValue: (field) => this.getFieldValue(field),
          setValue: (value) => this.setValue(value),
          hiddenFields: (hidden, fields) => this.hiddenFields(hidden, fields),
        };
        v.value = [];
      }
      if (v.field === 'rewardRule') {
        v.props = {
          ...v.props,
          parentCode: 'CRM20220914000000003',
          functionCode: 'reward_rules_list',
          // reward: this.reward,
          getValue: (field) => this.getFieldValue(field),
          setValue: (value) => this.setValue(value),
          hiddenFields: (hidden, fields) => this.hiddenFields(hidden, fields),
        };
        v.value = [];
      }
      if (v.field === 'crackRule') {
        v.props = {
          ...v.props,
          parentCode: 'CRM20220914000000003',
          functionCode: 'reward_rules_list',
          // reward: this.reward,
          getValue: (field) => this.getFieldValue(field),
          setValue: (value) => this.setValue(value),
          hiddenFields: (hidden, fields) => this.hiddenFields(hidden, fields),
        };
        v.value = [];
      }
      if (v.field === 'activeScanCodeType') {
        v.on = {
          ...v.on,
          change: (val) => {
            this.fApi.el('RangeList').activeScanCodeType = val;
          },
        };
      }
      if (this.formConfig.code === 'view') {
        v.props = {
          ...v.props,
          formConfig: this.formConfig,
          disabled: true,
        };
        this.buttons.submit = false;
      }
      if (this.formConfig.code === 'add' || this.formConfig.code === 'edit') {
        v.props = {
          ...v.props,
          formConfig: this.formConfig,
        };
      }
      if (this.formConfig.code === 'budget_edit') {
        v.props = {
          ...v.props,
          disabled: true,
          rowData: this.formConfig,
        };
      }
      return v;
    },
    formComplete() {
      if (this.formConfig.code === 'add') {
        this.onActivity();
      }
      if (
        this.formConfig.code === 'edit'
      || this.formConfig.code === 'view'
      || this.formConfig.code === 'budget_edit'
      ) {
        this.getDetail();
      }
    },
    onActivity(row) {
      const activeScanCodeType = this.getRule('activeScanCodeType');
      if (row === '1') {
        this.fApi.el('RangeList').activeScanCodeType = null;
        activeScanCodeType.options = this.ActiveScanCodeType.filter((v) => v.dictCode !== '2');
        this.hiddenFields(false, ['title4', 'rewardRule', 'title3', 'wayRule', 'channels', 'orgName']);
        this.hiddenFields(true, ['title5', 'crackRule']);
      } else if (row === '2') {
        this.fApi.el('RangeList').activeScanCodeType = null;
        activeScanCodeType.options = this.ActiveScanCodeType.filter((v) => v.dictCode === '3');
        this.hiddenFields(false, ['title5', 'crackRule']);
        this.hiddenFields(true, ['title4', 'rewardRule', 'title3', 'wayRule', 'channels', 'orgName']);
      } else {
        activeScanCodeType.options = [];
        this.$nextTick(() => {
          this.hiddenFields(true, ['title4', 'rewardRule', 'title3', 'wayRule', 'title5', 'crackRule']);
        });
      }
      this.setValue({
        activityType: row,
        activeScanCodeType: null,
      });
    },
    // 获取详情
    async getDetail() {
      const res = await request.post(
        '/cps-mobile/consumerscanactivitymain/query',
        { activityCode: this.formConfig.row.activityCode },
      );
      if (res.success) {
        const { result } = res;
        const param = {
          feeBudgetCode: result.budgetCode,
        };
        request.post('/tpm/tpmFeeBudgetController/list', param).then((res1) => {
          if (res1.success) {
            result.productLevelCode = res1.result.data[0].productLevelCode;
            if (result.activityType === '1') {
              this.hiddenFields(false, ['title4', 'rewardRule', 'title3', 'wayRule', 'channels', 'orgName']);
              this.hiddenFields(true, ['title5', 'crackRule']);
            } else if (result.activityType === '2') {
              this.hiddenFields(false, ['title5', 'crackRule']);
              this.hiddenFields(true, ['title4', 'rewardRule', 'title3', 'wayRule', 'channels', 'orgName']);
            } else {
              this.$nextTick(() => {
                this.hiddenFields(true, ['title4', 'rewardRule', 'title3', 'wayRule', 'title5', 'crackRule']);
              });
            }
            const activeScanCodeType = this.getRule('activeScanCodeType');
            this.fApi.el('RangeList').activeScanCodeType = result.activeScanCodeType;
            if (result.activeScanCodeType === '1') {
              this.$nextTick(() => {
                this.fApi.el('RangeList').tableData = result.orgReqVos;
              });
            } else if (result.activeScanCodeType === '3') {
              this.$nextTick(() => {
                this.fApi.el('RangeList').tableData = result.regionReqVos;
              });
            }
            // if (this.formConfig.code !== 'view') {
            //   if (result.activeScanCodeType === '1') {
            //     this.$nextTick(() => {
            //       this.fApi.el('RangeList').tableData = result.orgReqVos;
            //     });
            //   } else if (result.activeScanCodeType === '3') {
            //     this.$nextTick(() => {
            //       this.fApi.el('RangeList').tableData = result.regionReqVos;
            //     });
            //   }
            // }

            if (result.activityType === '1') {
              activeScanCodeType.options = this.ActiveScanCodeType.filter((v) => v.dictCode !== '2');
            } else {
              activeScanCodeType.options = this.ActiveScanCodeType.filter((v) => v.dictCode === '3');
            }
            const wayRule = (result.rewardRules || []).filter((v) => v.rewardType === '2');
            const rewardRule = (result.rewardRules || []).filter((v) => v.rewardType === '1');
            const crackRule = (result.rewardRules || []).filter((v) => v.rewardType === '3');
            result.wayRule = (wayRule || []).map((m) => {
              const obj = m;
              obj.scanTypeName = (this.scanTypeList.find((k) => k.dictCode === obj.scanType) || {}).dictValue || '';
              obj.awardWayName = (this.awardWayList.find((k) => k.dictCode === obj.awardWay) || {}).dictValue || '';
              obj.beneficiaryName = (this.beneficiaryList.find((k) => k.dictCode === obj.beneficiary) || {}).dictValue || '';
              obj.wayTypeName = (this.wayTypeList.find((k) => k.dictCode === obj.wayType) || {}).dictValue || '';
              obj.wayMoney = Number(obj.wayMoney);
              obj.distanceAstrict = Number(obj.distanceAstrict);
              return obj;
            });
            result.rewardRule = (rewardRule || []).map((m) => {
              const obj = m;
              obj.scanTypeName = (this.scanTypeList.find((k) => k.dictCode === obj.scanType) || {}).dictValue || '';
              obj.awardWayName = (this.awardWayList.find((k) => k.dictCode === obj.awardWay) || {}).dictValue || '';
              obj.beneficiaryName = (this.beneficiaryList.find((k) => k.dictCode === obj.beneficiary) || {}).dictValue || '';
              obj.wayTypeName = (this.wayTypeList.find((k) => k.dictCode === obj.wayType) || {}).dictValue || '';
              obj.wayMoney = Number(obj.wayMoney);
              obj.distanceAstrict = Number(obj.distanceAstrict);
              return obj;
            });
            result.crackRule = (crackRule || []).map((m) => {
              const obj = m;
              obj.scanTypeName = (this.scanTypeList.find((k) => k.dictCode === obj.scanType) || {}).dictValue || '';
              obj.awardWayName = (this.awardWayList.find((k) => k.dictCode === obj.awardWay) || {}).dictValue || '';
              obj.beneficiaryName = (this.beneficiaryList.find((k) => k.dictCode === obj.beneficiary) || {}).dictValue || '';
              obj.wayTypeName = (this.wayTypeList.find((k) => k.dictCode === obj.wayType) || {}).dictValue || '';
              obj.wayMoney = Number(obj.wayMoney);
              obj.distanceAstrict = Number(obj.distanceAstrict);
              return obj;
            });
            result.productReqVos = (result.productReqVos || []).map((m) => {
              const obj = m;
              obj.ext3 = obj.unit;
              obj.spec = obj.specification;
              obj.productType = obj.type;
              return obj;
            });
            let orgNames = '';
            (result.orgReqVos || []).forEach((m) => {
              const obj = m;
              orgNames += `${obj.orgName},`;
            });
            result.orgName = orgNames;
            result.datePicker = [result.activityStartTime, result.activityEndTime];
            result.orderDatePicker = [result.orderStartTime || '', result.orderEndTime || ''];
            this.setValue(result);
            this.row = res.result;
          }
        });
      }
    },
    // 客户类型和终端类型
    getTypeList() {
      const param = ['scan_type', 'cps_reward_type', 'wayType', 'cps_target_group'];
      request.post('/mdm/mdmdictdata/batchDictSelect', param).then((res) => {
        if (res.success) {
          this.scanTypeList = res.result.scan_type || [];
          this.awardWayList = res.result.cps_reward_type || [];
          this.wayTypeList = res.result.wayType || [];
          this.beneficiaryList = res.result.cps_target_group || [];
        }
      });
    },
    // 提交
    submit(e) {
      console.log(e);
      const formData = this.getFormData();
      console.log('formData', formData);
      const {
        wayRule, rewardRule, crackRule, activityType,
      } = formData;
      if (!formData) return false;
      let params = formData;
      if (!params.productReqVos || params.productReqVos.length === 0) {
        this.$message.error('请选择参与产品！');
        return;
      }
      if (((!params.rewardRule || params.rewardRule.length === 0) && (!params.wayRule || params.wayRule.length === 0)) && activityType === '1') {
        this.$message.error('请选择正式向奖励规则/反向奖励规则！');
        return;
      }
      // if ((!params.wayRule || params.wayRule.length === 0) && activityType === '1') {
      //   this.$message.error('请选择反向奖励规则！');
      //   return;
      // }
      if ((!params.crackRule || params.crackRule.length === 0) && activityType === '2') {
        this.$message.error('请选择开瓶奖励规则！');
        return;
      }
      params.activityStartTime = formData.datePicker && formData.datePicker.length > 0
        ? formData.datePicker[0]
        : null;
      params.activityEndTime = formData.datePicker && formData.datePicker.length > 0
        ? formData.datePicker[1]
        : null;
      params.orderStartTime = formData.orderDatePicker && formData.orderDatePicker.length > 0
        ? formData.orderDatePicker[0]
        : '';
      params.orderEndTime = formData.orderDatePicker && formData.orderDatePicker.length > 0
        ? formData.orderDatePicker[1]
        : '';
      params.productReqVos = (params.productReqVos || []).map((m) => {
        const obj = m;
        obj.id = '';
        obj.unit = obj.ext3;
        obj.specification = obj.spec;
        obj.type = obj.productType;
        return obj;
      });
      let reward = [];
      const wayRule1 = wayRule.map((v) => {
        const obj = v;
        return obj;
      });
      const rewardRule1 = rewardRule.map((v) => {
        const obj = v;
        return obj;
      });
      const crackRule1 = crackRule.map((v) => {
        const obj = v;
        return obj;
      });
      reward = [...wayRule1, ...rewardRule1, ...crackRule1];
      params.rewardRules = reward;
      const activeScanCodeType = this.getFieldValue('activeScanCodeType');
      let { tableData } = this.fApi.el('RangeList');

      if (activeScanCodeType === '1') {
        tableData = tableData.map((v) => {
          const obj = v;
          delete obj.id;
          return obj;
        });
        params.orgReqVos = tableData;
        params.regionReqVos = [];
      } else if (activeScanCodeType === '3') {
        params.regionReqVos = tableData;
        params.orgReqVos = [];
      }

      delete params.datePicker;
      delete params.orderDatePicker;
      delete params.wayRule;
      delete params.rewardRule;
      delete params.crackRule;
      delete params.feeOrgCode;
      delete params.orgName;
      delete params.title0;
      delete params.title1;
      delete params.title2;
      delete params.title3;
      delete params.title4;
      delete params.title5;
      console.log(params);
      let url = '/cps-mobile/consumerscanactivitymain/save';

      if (this.formConfig.code === 'edit') {
        url = '/cps-mobile/consumerscanactivitymain/update';
        params = Object.assign(this.row, params);
      }
      if (this.formConfig.code === 'budget_edit') {
        url = '/cps-mobile/consumerscanactivitymain/budgetUpdate';
        params = Object.assign(this.row, params);
      }
      request.post(url, params).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });

          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
