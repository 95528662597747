<template>
  <div class="detailed-table">
    <vxe-toolbar v-if="!disabled">
      <template v-slot:buttons>
        <el-link type="primary" icon="el-icon-plus" @click="addTable"
          >新增</el-link
        >
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="xTable"
      resizable
      show-overflow
      :data="valueList"
      :edit-config="{
        trigger: 'click',
        mode: 'cell',
        activeMethod: activeCellMethod,
      }"
      @radio-change="radioChangeEvent"
    >
      <vxe-table-column
        v-for="(item, k) in columns"
        :key="k"
        v-bind="itemRender(item)"
      >
        <template #edit="{ row, rowIndex }" v-if="item.field === 'wayMoney'">
          <div>
            <div v-if="row.wayType === '2'">
              <div class="edit" @click="openRandom(row, rowIndex)">
                {{ row.randomName || "编辑" }}
              </div>
            </div>
            <div v-else>
              <el-input-number
                :precision="2"
                v-model="row.wayMoney"
                :controls="false"
                :disabled="disabled"
              ></el-input-number>
            </div>
          </div>
        </template>
        <template #default="{ row, rowIndex }">
          <div v-if="item.field === 'wayMoney'">
            <div v-if="row.wayType === '2'">
              <div class="edit" @click="openRandom(row, rowIndex)">
                {{ row.randomName || "编辑" }}
              </div>
            </div>
            <div v-else>
              <el-input-number
                :precision="2"
                v-model="row.wayMoney"
                :controls="false"
                :disabled="disabled"
              ></el-input-number>
            </div>
          </div>
          <div>{{ resetText(row, item.field) }}</div>
        </template>
      </vxe-table-column>
      <vxe-table-column
        fixed="right"
        title="操作"
        align="center"
        width="80"
        v-if="!disabled"
      >
        <template slot-scope="scope">
          <el-popconfirm
            title="此操作将永久删除该数据？"
            confirm-button-type="text"
            @confirm="removeRow(scope.row, scope.rowIndex)"
          >
            <el-button
              v-if="!scope.row.disabled"
              class="text-red"
              slot="reference"
              type="text"
              icon="el-icon-delete"
            ></el-button>
          </el-popconfirm>
        </template>
      </vxe-table-column>
    </vxe-table>
    <SelectConfig ref="SelectConfig" @onGetSelect="onGetSelect" />
    <Modal
      :modalConfig.sync="modalConfig"
      :visible.sync="modalConfig.visible"
      @onClose="closeModal"
    >
      <component
        :ref="formName"
        :is="formName"
        :formConfig="formConfig"
        :disabled="disabled"
        @closeModal="closeModal"
      />
      <div class="dialog-footer">
        <el-button icon="el-icon-submit" type="primary" @click="submit"
          >提交</el-button
        >
        <el-button icon="el-icon-close" type="danger" @click="closeModal"
          >关闭</el-button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import request from '../../../../../../../utils/request';
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';
import Modal from '../../../../../../../components/modal';
import RandomModal from './random_modal.js';

export default {
  props: {
    value: Array,
    disabled: Boolean,
    getValue: Function,
    setValue: Function,
    hiddenFields: Function,
    functionCode: String,
    parentCode: String,
    reward: String,
    rowData: Object,
  },
  components: {
    SelectConfig,
    Modal,
    RandomModal,
  },
  data() {
    return {
      modalConfig: {
        width: '60%',
        title: '定额随机',
        type: 'Modal',
        visible: false,
      },
      randomIndex: 0,
      formName: 'RandomModal',
      formConfig: {},
      columns: [],
      fieldList: null,
      numberList: ['wayMoney', 'distanceAstrict'], // 数字格式
      selectRow: null,
      scanTypeList: [], // 扫码类型
      awardWayList: [], // 每扫基本单位给终端
      wayTypeList: [], // 额度类型
      beneficiaryList: [], // 受益对象
      rowIndex: 0,
      field: '',
      valueList: [
        {
          scanType: '',
          scanTypeName: '',
          beneficiary: '',
          beneficiaryName: '',
          wayType: '',
          wayTypeName: '',
          wayMoney: '',
          distanceAstrict: '',
          budgetSubjectName: '',
          budgetSubjectCode: '',
          budgetName: '',
          budgetCode: '',
          feeOrgCode: '',
          productLevelCode: '',
          budgetCanUserAmount: '',
          rewardType: '2',
          randomName: '',
          quotaRandomId: '',
        },
      ],
    };
  },
  watch: {
    functionCode(val) {
      if (val) {
        this.getColumn(val);
      } else {
        this.columns = [];
      }
    },
    value(val) {
      this.valueList = val;
    },
    reward(val) {
      console.log(val);
    },
  },
  created() {
    this.getTypeList();
    if (this.functionCode) {
      this.getColumn(this.functionCode);
    }
  },
  methods: {
    submit() {
      const data = this.$refs.RandomModal.submit();
      console.log(data);
      this.valueList[this.randomIndex].quotaRandomId = data.id;
      this.valueList[this.randomIndex].randomName = data.randomName;
      this.closeModal();
      this.$emit('input', this.valueList);
    },
    closeModal() {
      this.modalConfig.visible = false;
    },
    openRandom(row, rowIndex) {
      if (this.disabled) {
        return;
      }
      this.randomIndex = rowIndex;
      this.formName = 'RandomModal';
      this.modalConfig.title = '定额随机';
      this.modalConfig.visible = true;
      this.modalConfig.type = 'Modal';
      this.modalConfig.width = '60%';
    },
    // 删除行
    removeRow(row, rowIndex) {
      this.valueList.splice(rowIndex, 1);
      this.$emit('change', this.valueList);
    },
    // 新增数据
    addTable() {
      if (this.valueList.length >= 5) {
        this.$message('反向奖励规则最多只能有5条数据');
        return false;
      }
      const wayRuleList = {
        scanType: '',
        scanTypeName: '',
        beneficiary: '',
        beneficiaryName: '',
        wayType: '',
        wayTypeName: '',
        wayMoney: '',
        distanceAstrict: '',
        budgetSubjectName: '',
        budgetSubjectCode: '',
        budgetName: '',
        budgetCode: '',
        feeOrgCode: '',
        productLevelCode: '',
        budgetCanUserAmount: '',
        rewardType: '2',
        randomName: '',
        quotaRandomId: '',
      };
      this.valueList = this.valueList.concat(wayRuleList);
    },
    // 选择列表数据
    radioChangeEvent({ row }) {
      this.selectRow = row;
    },

    // 获取表格配置
    getColumn(functionCode) {
      const parentCode = this.parentCode
        ? this.parentCode
        : this.$store.state.menus.selectMenu;

      request
        .post('/mdm/mdmtableconfig/query', { functionCode, parentCode })
        .then((res) => {
          if (res.success) {
            const _fields = {};
            res.result.column.forEach((v) => {
              _fields[v.field] = '';
            });

            this.fieldList = _fields;
            this.columns = res.result.column.filter((v) => v.visible);
            if (this.valueList.length === 0) {
              const params = {};
              const list = JSON.parse(JSON.stringify(this.valueList || []));
              list.push({ ...this.fieldList, ...params });
              this.$emit('input', list);
              this.selectRow = null;
            }
          }
        });
    },
    // 单元格渲染
    itemRender(item) {
      const { title, field, type } = item;
      let column = {
        title,
        field,
        minWidth: 150,
      };
      let editRender = null;
      if (field === 'checkTyperadio' || field === 'checkTypecheckbox') {
        column = {
          type: field === 'checkTyperadio' ? 'radio' : 'checkbox',
          width: '36',
          fixed: 'left',
          visible: !this.disabled,
        };
      } else if (!this.disabled && type === 'input') {
        if (this.numberList.includes(field)) {
          editRender = {
            name: 'ElInputNumber',
            props: {
              placeholder: `请输入${title}`,
              clearable: true,
              controls: false,
              min: 0,
              precision: field === 'distanceAstrict' ? 0 : 2,
              value: undefined,
            },
            events: {
              change: ({ row, rowIndex }) => {
                this.changeRow(field, row, rowIndex);
              },
            },
          };
        } else {
          editRender = {
            name: 'ElInput',
            props: {
              placeholder: `请输入${title}`,
              clearable: true,
            },
          };
        }
      } else if (!this.disabled && type === 'select') {
        editRender = { name: 'ElSelect' };

        if (
          field === 'scanType'
          || field === 'awardWay'
          || field === 'wayType'
          || field === 'beneficiary'
        ) {
          editRender = {
            ...editRender,
            props: {
              placeholder: `请选择${title}`,
              clearable: true,
              filterable: true,
              remote: true,
              remoteMethod: (query) => this.remoteMethod(field, query),
            },
            loading: this.$store.state.buttonApilsLoading,
            events: {
              change: ({ row, rowIndex }) => {
                this.changeRow(field, row, rowIndex);
              },
            },
          };
          if (field === 'scanType') {
            // editRender.options = this.scanTypeList;
            editRender.options = [
              { dictCode: '1', dictValue: '消费者扫码' },
              { dictCode: '2', dictValue: '终端扫码' },
            ];
            editRender.optionProps = {
              label: 'dictValue',
              value: 'dictCode',
            };
          } else if (field === 'awardWay') {
            // editRender.options = this.awardWayList;
            if (
              (this.valueList[this.rowIndex] || {}).beneficiary
                === 'terminal'
              && (this.valueList[this.rowIndex] || {}).scanType === '1'
            ) {
              editRender.options = [{ dictCode: '1', dictValue: '红包' }];
            } else if (
              ((this.valueList[this.rowIndex] || {}).beneficiary === 'dealer'
                || (this.valueList[this.rowIndex] || {}).beneficiary
                  === 'group')
              && (this.valueList[this.rowIndex] || {}).scanType === '1'
            ) {
              editRender.options = [
                { dictCode: '3', dictValue: '费用(开票)' },
                { dictCode: '4', dictValue: '费用(不开票)' },
              ];
            } else if (
              (this.valueList[this.rowIndex] || {}).beneficiary === 'dealer'
              && (this.valueList[this.rowIndex] || {}).scanType === '2'
            ) {
              editRender.options = [
                { dictCode: '3', dictValue: '费用(开票)' },
                { dictCode: '4', dictValue: '费用(不开票)' },
              ];
            } else {
              editRender.options = this.awardWayList;
            }
            editRender.optionProps = {
              label: 'dictValue',
              value: 'dictCode',
            };
          } else if (field === 'wayType') {
            // if ((this.valueList[this.rowIndex] || {}).beneficiary === 'dealer') {
            //   editRender.options = [{ dictCode: '1', dictValue: '固定额度' }];
            // } else if ((this.valueList[this.rowIndex] || {}).beneficiary === 'terminal') {
            //   // editRender.options = [{ dictCode: '2', dictValue: '随机额度' }];
            //   editRender.options = this.wayTypeList;
            // } else if ((this.valueList[this.rowIndex] || {}).beneficiary === 'group') {
            //   editRender.options = [{ dictCode: '1', dictValue: '固定额度' }];
            // } else {
            //   editRender.options = this.wayTypeList;
            // }
            editRender.options = this.wayTypeList;
            editRender.optionProps = {
              label: 'dictValue',
              value: 'dictCode',
            };
          } else if (field === 'beneficiary') {
            // if ((this.valueList[this.rowIndex] || {}).awardWay === '1') {
            //   editRender.options = [{ dictCode: 'terminal', dictValue: '终端' }];
            // } else if ((this.valueList[this.rowIndex] || {}).awardWay === '3') {
            //   editRender.options = [{ dictCode: 'group', dictValue: '团购' }];
            // } else {
            //   editRender.options = this.beneficiaryList;
            // }
            if ((this.valueList[this.rowIndex] || {}).scanType === '1') {
              editRender.options = this.beneficiaryList;
            } else if ((this.valueList[this.rowIndex] || {}).scanType === '2') {
              editRender.options = [
                { dictCode: 'dealer', dictValue: '经销商' },
              ];
            } else {
              editRender.options = this.beneficiaryList;
            }
            editRender.optionProps = {
              label: 'dictValue',
              value: 'dictCode',
            };
          }
        }
      } else if (!this.disabled && type === 'DatePicker') {
        editRender = {
          name: 'ElDatePicker',
          props: {
            placeholder: `请选择${title}`,
            clearable: true,
            valueFormat: 'yyyy-MM-dd',
          },
        };
      }

      if (field === 'budgetSubjectName' || field === 'budgetName') {
        editRender = {
          name: 'ElInput',
          props: {
            placeholder: `请输入${title}`,
            clearable: true,
          },
          events: {
            focus: ({ row, rowIndex }) => {
              console.log(333);
              this.openModal(row, rowIndex, field);
            },
          },
        };
      }

      return {
        ...column,
        editRender,
      };
    },
    // 打开弹窗
    openModal(row, rowIndex, field) {
      this.field = field;
      if (this.disabled && (this.rowData || {}).code !== 'budget_edit') return false;
      this.dIndex = rowIndex;
      if (field === 'budgetSubjectName') {
        this.dIndex = rowIndex;
        const params = {
          data: row.budgetSubjectList || [],
          selectionList: [],
          functionCode: 'budget_subjects_list_1',
          idKey: 'budgetSubjectsCode',
          noReset: true,
          paramData: {
            enableStatus: '009',
          },
        };
        this.$refs.SelectConfig.openSelectModal(params);
      } else if (field === 'budgetName') {
        if (!(this.valueList[this.dIndex] || {}).budgetSubjectCode) {
          this.$message.error('请先选预算科目！');
          return false;
        }
        this.dIndex = rowIndex;
        const params = {
          data: row.budgetList || [],
          selectionList: [],
          functionCode: 'budget_list_1',
          idKey: 'budgetCode',
          noReset: true,
          paramData: {
            enableStatus: '009',
            budgetSubjectCode: this.valueList[this.dIndex].budgetSubjectCode,
          },
        };
        this.$refs.SelectConfig.openSelectModal(params);
      }
    },
    // 弹框选中
    onGetSelect(val) {
      console.log('弹框选中的数据----', val);
      if (this.field === 'budgetSubjectName') {
        this.$set(
          this.valueList[this.dIndex],
          'budgetSubjectCode',
          val[0].budgetSubjectsCode,
        );
        this.$set(this.valueList[this.dIndex], 'budgetSubjectList', val);
        this.$set(
          this.valueList[this.dIndex],
          'budgetSubjectName',
          val[0].budgetSubjectsName,
        );
        this.$set(this.valueList[this.dIndex], 'budgetCode', '');
        this.$set(this.valueList[this.dIndex], 'budgetName', '');
        this.$set(this.valueList[this.dIndex], 'feeOrgCode', '');
        this.$set(this.valueList[this.dIndex], 'budgetCanUserAmount', '');
      } else if (this.field === 'budgetName') {
        this.$set(
          this.valueList[this.dIndex],
          'budgetCode',
          val[0].feeBudgetCode,
        );
        this.$set(this.valueList[this.dIndex], 'budgetList', val);
        this.$set(
          this.valueList[this.dIndex],
          'budgetName',
          val[0].feeBudgetTypeName,
        );
        this.$set(this.valueList[this.dIndex], 'feeOrgCode', val[0].orgCode);
        this.$set(
          this.valueList[this.dIndex],
          'productLevelCode',
          val[0].productLevelCode,
        );
        this.$set(
          this.valueList[this.dIndex],
          'budgetCanUserAmount',
          val[0].canUseAmount,
        );
      }
      this.$emit('input', this.valueList);
    },
    // 渲染回显
    resetText(row, field) {
      let text = '';
      if (this.numberList.includes(field)) {
        text = row[field] ? row[field].toFixed(2) : '';
      } else if (field === 'scanType') {
        text = row.scanTypeName || '';
      } else if (field === 'awardWay') {
        text = row.awardWayName || '';
      } else if (field === 'wayType') {
        text = row.wayTypeName || '';
      } else if (field === 'beneficiary') {
        text = row.beneficiaryName || '';
      } else {
        text = row[field];
      }
      return text;
    },
    // 单元格是否允许编辑
    activeCellMethod({ column, row, rowIndex }) {
      console.log(row, rowIndex);
      this.rowIndex = rowIndex;
      return true;
    },
    // 远程搜索
    remoteMethod(field, query, index) {
      if (
        field === 'scanType'
        || field === 'awardWay'
        || field === 'wayType'
        || field === 'beneficiary'
      ) {
        this.getTypeList(query);
      }
    },
    // 编辑监听
    changeRow(field, row, index) {
      const list = JSON.parse(JSON.stringify(this.valueList));
      console.log(list);
      let _item = null;

      switch (field) {
        case 'scanType':
          _item = this.scanTypeList.find((v) => v.dictCode === row.scanType);

          list[index] = {
            ...list[index],
            scanTypeName: _item ? _item.dictValue : '',
            beneficiary: '',
            beneficiaryName: '',
          };
          break;
        case 'awardWay':
          _item = this.awardWayList.find((v) => v.dictCode === row.awardWay);

          list[index] = {
            ...list[index],
            awardWayName: _item ? _item.dictValue : '',
          };
          break;
        case 'wayType':
          _item = this.wayTypeList.find((v) => v.dictCode === row.wayType);

          list[index] = {
            ...list[index],
            wayTypeName: _item ? _item.dictValue : '',
          };
          break;
        case 'beneficiary':
          _item = this.beneficiaryList.find(
            (v) => v.dictCode === row.beneficiary,
          );

          list[index] = {
            ...list[index],
            beneficiaryName: _item ? _item.dictValue : '',
            awardWay: '',
            awardWayName: '',
          };
          break;
        default:
          break;
      }

      this.$emit('input', list);
    },
    // 计算费用
    countFn(row) {
      const { wayMoney, distanceAstrict } = row;
      let countNum = 0;
      if (wayMoney) {
        countNum = Number(wayMoney).toFixed(2);
      } else if (distanceAstrict) {
        countNum = Number(distanceAstrict).toFixed(2);
      }
      return countNum;
    },
    // 客户类型和终端类型
    getTypeList(query) {
      const param = [
        'scan_type',
        'cps_reward_type',
        'wayType',
        'cps_target_group',
      ];
      request.post('/mdm/mdmdictdata/batchDictSelect', param).then((res) => {
        if (res.success) {
          this.scanTypeList = res.result.scan_type || [];
          this.awardWayList = res.result.cps_reward_type || [];
          this.wayTypeList = res.result.wayType || [];
          this.beneficiaryList = res.result.cps_target_group || [];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detailed-table {
  padding-left: 50px;

  .text-red {
    color: #f56c6c !important;
  }
  .edit {
    color: #b44b39;
    cursor: pointer; //鼠标停留变手指
  }
}
</style>
