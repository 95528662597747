<!--
 * @Author: yuanshengsong
 * @Date: 2022-03-17 10:09:51
 * @LastEditTime: 2022-03-22 10:58:17
 * @LastEditors: yuanshengsong
 * @Description:
 * @param: paramAlias
 * @return: returnAlias
-->
<template>
  <div class="btnBox">
    <div v-for="(item, k) in activityTypeList" :key="k">
      <el-button v-if="value === item.dictCode" type="primary" plain class="btn" @click="handleClick(item)">{{
        item.dictValue
      }}</el-button>
      <el-button v-else plain class="btn" @click="handleClick(item)">{{ item.dictValue }}</el-button>
    </div>
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'BudgetSubject',
  components: {},
  props: {
    value: String,
    disabled: Boolean,
    getValue: Function,
  },
  data() {
    return {
      activityTypeList: [],
    };
  },
  async created() {
    await this.getTypeList();
  },
  methods: {
    handleClick(item) {
      if (!this.disabled) {
        this.$emit('change', item.dictCode);
        this.$emit('input', item.dictCode);
      }
    },
    // 客户类型和终端类型
    getTypeList() {
      const param = { dictTypeCode: 'cpps_activity_type' };
      request.post('/mdm/mdmdictdata/list', param).then((res) => {
        if (res.success) {
          this.activityTypeList = res.result.filter((item) => item.dictCode !== '3' && item.dictCode !== '4') || [];
          // this.activityTypeList = res.result || [];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.btnBox{
    display: flex;
}
.btn{
    margin-right: 20px;
}
</style>
