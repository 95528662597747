var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "btnBox" },
    _vm._l(_vm.activityTypeList, function (item, k) {
      return _c(
        "div",
        { key: k },
        [
          _vm.value === item.dictCode
            ? _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.dictValue))]
              )
            : _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.dictValue))]
              ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }