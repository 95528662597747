<!--
 * @Autor: yzr
 * @Date: 2021-07-19 14:23:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-11-03 11:26:07
 * @Description: 协议模板主体列表
-->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from './form';

export default {
  name: 'consumerScan',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestType: 'POST',
      requestUrl: '/cps-mobile/consumerscanactivitymain/list',
      params: {
      },
      formConfig: {},
      modalConfig: {
      },
      searchFormData: {},
      formData: {},
    };
  },
  async created() {
    await this.getConfigList('consumer_consumerScan_list');
  },
  //   async mounted() {
  //     await this.getPath();
  //   },
  //   deactivated() {
  //     this.formData = {};
  //     this.searchFormData = {};
  //     this.getList();
  //   },
  methods: {
    // setColumn(v) {
    //   const rowData = v;
    //   return rowData;
    // },
    modalClick({ val, row }) {
    //   this.modalConfig = {
    //     title: '消费者扫码活动',
      //   };
      console.log(val);
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'view' || code === 'budget_edit') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      }
    },
    // disable(type) {
    //   let url = '/cps-mobile/consumerscanactivitymain/enable';
    //   if (type) url = '/cps-mobile/consumerscanactivitymain/disable';
    //   request.patch(url, this.selection).then((res) => {
    //     if (!res.success) return this.$message.error(res.message);
    //     this.$message.success(res.message);
    //     this.getList();
    //   });
    // },
    // clickVisible({ val, row }) {
    //   return true;
    // },
    // 监听页面跳转
    // getPath() {
    //   if (Object.keys(this.$route.params).length !== 0) {
    //     this.formData.templateCode = this.$route.params.templateCode;
    //     // this.searchFormData = {
    //     //   ...this.searchFormData,
    //     //   templateCode: this.$route.params.templateCode,
    //     // };
    //     this.searchFormData.templateCode = this.$route.params.templateCode;
    //     this.getList();
    //     // this.searchFormData = {};
    //   }
    // },
  },
//   watch: {
//     $route: 'getPath',
//   },
};
</script>
