<template>
  <div v-if="activeScanCodeType">
    <vxe-toolbar v-if="!disabled">
      <template v-slot:buttons>
        <el-button type="primary" @click="openModal">添加{{ buttonText }}</el-button>
        <el-button @click="clear">清空</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table ref="xTable" :data="tableData.slice((tablePage.currentPage-1)*tablePage.pageSize,tablePage.currentPage*tablePage.pageSize)">
      <vxe-table-column
        v-for="(item, index) in columns"
        :key="index + (item.field || item.fixed) + (item.formorder || '')"
        v-bind="item"
        :cellRender="item.cellRender"
        :tree-node="item.treeNode"
        :min-width="item.minWidth || 100"
      >
      </vxe-table-column>
       <vxe-table-column
        fixed="right"
        title="操作"
        align="center"
        width="80"
        v-if="!disabled"
      >
        <template slot-scope="scope">
          <el-popconfirm
            title="此操作将永久删除该数据？"
            confirm-button-type="text"
            @confirm="removeRow(scope.row, scope.rowIndex)"
          >
            <el-button
              v-if="!scope.row.disabled"
              class="text-red"
              slot="reference"
              type="text"
              icon="el-icon-delete"
            ></el-button>
          </el-popconfirm>
        </template>
      </vxe-table-column>
    </vxe-table>
    <div class="page-pageination">
         <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tablePage.currentPage"
          :page-size="tablePage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.length"
        >
        </el-pagination>
    </div>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  props: {
    disabled: Boolean,
    formConfig: Object,
  },
  components: {
    SelectConfig,
  },
  data() {
    return {
      buttonText: '',
      columns: [],
      tableData: [],
      functionCode: '',
      idKey: 'orgCode',
      activeScanCodeType: '', // 活动范围类型
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      pageCode: '',
    };
  },
  watch: {
    activeScanCodeType: {
      immediate: true,
      async handler(val) {
        // this.tableData = [];
        if (val === '1') {
          // 企业组织
          this.functionCode = 'business_organization_list_1';
          this.idKey = 'orgCode';
          this.buttonText = '企业组织';
          await this.getColumns();
        } else if (val === '2') {
          // 经销商
          // this.functionCode = 'business_organization_list_1';
          // this.idKey = 'orgCode';
          // this.buttonText = '经销商';
        } else if (val === '3') {
          // 行政区域
          this.functionCode = 'administrative_region';
          this.idKey = 'cityCode';
          this.buttonText = '行政区域';
          await this.getColumns();
        }
      },
    },
  },
  created() {

  },
  mounted() {
    console.log(this);
  },
  methods: {
    async getTableList() {
      console.log('111');
      console.log(this.formConfig);
      console.log(this.tableData);
      // const params = {
      //   id: this.formConfig.row.id,
      //   pageSize: this.tablePage.pageSize,
      //   pageNum: this.tablePage.currentPage,
      // };
      // await request.post('/cps-mobile/consumerscanactivitymain/findOrgPageList', params).then((res) => {
      //   console.log('222', this);
      //   console.log(res);
      //   if (res.success) {
      //     this.tableData = res.result.data;
      //     this.tablePage.totalResult = res.result.count;
      //     // this.$refs.xTable.updateData();
      //     console.log(this.tableData);
      //     // this.getColumns();
      //   }
      // }).catch((err) => {
      //   console.log('3333');
      //   console.log(err);
      // });
    },
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
    },
    handleCurrentChange(val) {
      this.tablePage.currentPage = val;
    },
    // 获取列表配置
    getColumns() {
      const params = {
        functionCode: this.functionCode,
      };
      request.post('/mdm/mdmtableconfig/query', params).then((res) => {
        if (res.success) {
          this.columns = res.result.column.filter((v) => v.field !== 'checkTypecheckbox');
        }
      });
    },
    // 打开弹窗
    openModal() {
      const params = {
        data: this.tableData,
        functionCode: this.functionCode,
        idKey: this.idKey,
        paramData: {
          enableStatus: '009',
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },

    // 选择回调
    onGetSelect(val) {
      this.tableData = val;
    },

    // 删除
    removeRow(row, rowIndex) {
      this.tableData.splice(rowIndex, 1);
    },

    // 清空
    clear() {
      this.tableData = [];
    },
  },
};
</script>
<style lang="less" scoped>

/deep/ .page-pageination {
    text-align: right;
    padding: 10px 0;
    .el-input {
      display: inline-block !important;
    }
  }

</style>
