var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      !_vm.disabled
        ? _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: { click: _vm.addTable },
                        },
                        [_vm._v("新增")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2948762210
            ),
          })
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "fineTable",
          attrs: { "show-overflow": "", data: _vm.subjectList },
          on: {
            "checkbox-all": _vm.selectAllChangeEvent,
            "checkbox-change": _vm.selectChangeEvent,
          },
        },
        [
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "productCode",
              title: "产品编码",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "productName",
              title: "产品名称",
            },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "ext3", title: "基本单位" },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "spec", title: "包装规格" },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "productType",
              title: "商品类型",
            },
          }),
          !_vm.disabled
            ? _c("vxe-table-column", {
                attrs: {
                  fixed: "right",
                  title: "操作",
                  align: "center",
                  width: "80",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                title: "此操作将永久删除该数据？",
                                "confirm-button-type": "text",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.removeRow(scope.row)
                                },
                              },
                            },
                            [
                              !scope.row.disabled
                                ? _c("el-button", {
                                    staticClass: "text-red",
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      icon: "el-icon-delete",
                                    },
                                    slot: "reference",
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3511404334
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }