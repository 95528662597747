/*
 * @Author: 冯杰
 * @Date: 2021-09-23 16:33:35
 * @LastEditTime: 2021-11-08 15:29:12
 * @FileName: 新增协议阶梯
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import DetailVos from '../../../../components/arrange.vue';

formCreate.component('DetailVos', DetailVos);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'random_form_1',
    };
  },
  methods: {
    formComplete() {
      this.hiddenFields(true, ['singleRandomCount']);
      this.disabled(true, ['randomCode', 'singleRandomCount']);
      this.getRule('detailVos').props.disabled = true;
      const randomType = this.getRule('randomType');
      const randomCode = this.getRule('randomCode');
      randomCode.optionsKey = { label: 'randomName', value: 'randomCode' };
      randomType.on = {
        ...randomType.on,
        change: (name) => {
          if (name) {
            this.disabled(false, ['randomCode']);
          } else {
            this.disabled(true, ['randomCode']);
          }
          if (name === 'consumer') {
            this.hiddenFields(false, 'singleRandomCount');
          } else {
            this.hiddenFields(true, 'singleRandomCount');
          }
          randomCode.value = '';
          randomCode.restfulParams = {
            randomType: name,
          };
        },
      };
      randomCode.on = {
        ...randomCode.on,
        change: (val) => {
          if (val) {
            request.get('/cps-mobile/quotaRandomMaintain/findByCode', { code: val }).then((res) => {
              if (res.success) {
                console.log(res.result);
                this.setValue({
                  detailVos: res.result.detailVos,
                  id: res.result.id,
                  randomName: res.result.randomName,
                  singleRandomCount: res.result.singleRandomCount,
                });
              }
            });
          }
        },
      };
    },

    setRule(item) {
      const val = item;
      if (val.field === 'randomType') {
        val.props = {
          ...val.props,
          min: 0,
          precision: 0,
          controls: false,
        };
      }
      if (val.field === 'randomCode') {
        val.restfulParams = {
          randomType: null,
        };
        val.restful = '/cps-mobile/quotaRandomMaintain/findByRandomType';
        val.restType = 'GET';
        val.props = {
          ...val.props,
          filterable: true,
          remote: true,
          remoteParams: 'randomName',
        };
        val.refresh = true;
      }
      if (val.field === 'singleRandomCount') {
        val.props = {
          ...val.props,
          min: 0,
          precision: 0,
          controls: false,
        };
      }
      if (val.field === 'detailVos') {
        val.value = [];
        val.props = {
          ...val.props,
          showFooter: true,
        };
        val.on = {
          ...val.on,
          totalData: (row) => {
            this.setValue({
              weightedAvg: row,
            });
          },
        };
      }
      return val;
    },
    // 提交
    submit() {
      return this.getFormData();
    },
  },
};
